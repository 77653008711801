import {
  getCategoriesObject,
  getMainCategoryObject,
  mapCategory
} from "./mapCategories"
import { getImageData } from "./mapImage"
import { getFullAddress } from "./addresses"
import type {
  ProductPageContent,
  ProductCategoryPageContent,
  ContentTypeMap,
  ProductBrandPageContent,
  ArticlePageContent,
  WikiArticlePageContent,
  PickupPointsDataAlgolia
} from "~/types/Algolia"
import { Entry } from "contentful"
import type {
  ProductPageFilter,
  PromotionBrandPage,
  PromotionInternalPage
} from "~/types/cms"
import { getCompactTimetable } from "./timetables"

export const checkEnvSite = import.meta.env.VUE_APP_ALGOLIA_ENV_SUFFIX ?? ""

const contentTypesMap: ContentTypeMap = {
  productPage: {
    indexName: `TOP_PRODUCTS${checkEnvSite}`,
    contentMap: (item: any): ProductPageContent => {
      // Map the fields from the item to the ProductPageContent interface
      return {
        objectID: `product_${item?.fields?.productCode}`,
        productName: item?.fields?.productName,
        slug: item?.fields?.slug,
        productCode: item?.fields?.productCode,
        productEan: item?.fields?.productEan ?? undefined,
        mainImage: getImageData(item?.fields?.mainImage),
        mainCategory: mapCategory(item?.fields?.mainCategory),
        mainCategoryHierarchy: getMainCategoryObject(
          item?.fields?.mainCategory
        ),
        categories:
          item?.fields?.categories &&
          getCategoriesObject(item?.fields?.categories),
        promotionCategories: item?.fields?.promotionCategories
          ? item?.fields?.promotionCategories
              .filter(
                (
                  promotion: Entry<PromotionBrandPage | PromotionInternalPage>
                ) => promotion?.fields
              )
              .map(
                (
                  promotion: Entry<PromotionBrandPage | PromotionInternalPage>
                ) => promotion?.fields?.promotionName
              )
          : [],
        shortDescription: item?.fields?.shortDescription,
        brand: {
          name: item?.fields?.brand?.fields?.brandName,
          slug: item?.fields?.brand?.fields?.slug
        },
        deductibleProduct: item?.fields?.deductibleProduct,
        price: {},
        inStock: true,
        lastItems: false,
        ratings: {},
        unit: {
          total: item?.fields?.quantity,
          type: item?.fields?.quantityUom
        },
        activeSubstance: item?.fields?.activeSubstance,
        symptoms: item?.fields?.associatedSymptoms,
        expirationDate: "",
        commercialTags: item?.fields?.commercialTags ?? [],
        firstPublishing_timestamp: new Date(
          item.fields.algoliaFirstPublishingDate
        ).getTime(),
        algoliaFilterTags: item.fields.algoliaFilterTags ?? [],
        productTags: item.fields.productTags ?? [],
        sellabilityStatus:
          item.fields.sellabilityInfo?.fields?.sellabilityStatus ?? "sellable",
        genericDrug: item.fields?.genericDrug,
        correspondingEquivalentDrugs: item?.fields?.genericDrugs?.map(
          (item: Entry<ProductPageFilter>) => item?.fields?.productCode
        )
      }
    }
  },
  productCategoryPage: {
    indexName: `TOP_CATEGORIES${checkEnvSite}`,
    contentMap: (item: any): ProductCategoryPageContent => {
      // Map the fields from the item to the ProductCategoryPageContent interface
      return {
        objectID: item.sys.id,
        categoryName: item.fields.categoryName,
        slug: item.fields.slug,
        parentCategory: mapCategory(item.fields?.parentCategory)
      }
    }
  },
  wikiArticlePage: {
    indexName: `TOP_WIKI_ARTICLES${checkEnvSite}`,
    contentMap: (item: any): WikiArticlePageContent => {
      // Map the fields from the item to the WikiArticlePageContent interface
      const firstLetterChar =
        item?.fields?.firstLetter?.toUpperCase() ??
        item.fields.title.charAt(0).toUpperCase()

      return {
        objectID: `wikiArticle_${item.sys.id}`,
        title: item.fields.title,
        slug: item.fields.slug,
        shortDescription: item.fields.shortDescription,
        category: {
          name: item?.fields?.category?.fields?.name,
          slug: item?.fields?.category?.fields?.slug
        },
        firstLetter: isNaN(firstLetterChar) ? firstLetterChar : "#"
      }
    }
  },
  articlePage: {
    indexName: `TOP_MAGAZINE_ARTICLES${checkEnvSite}`,
    contentMap: (item: any): ArticlePageContent => {
      return {
        objectID: `article_${item.sys.id}`,
        title: item.fields.title,
        slug: item.fields.slug
      }
    }
  },
  productBrandPage: {
    indexName: `TOP_BRANDS${checkEnvSite}`,
    contentMap: (item: any): ProductBrandPageContent => {
      // Map the fields from the item to the BrandPageContent interface
      const firstLetterChar =
        item?.fields?.firstLetter?.toUpperCase() ??
        item.fields.brandName.charAt(0).toUpperCase()

      return {
        objectID: item.sys.id,
        brandName: item.fields.brandName,
        slug: item.fields.slug,
        firstLetter: isNaN(firstLetterChar) ? firstLetterChar : "#",
        description: item.fields.cardDescription,
        image: getImageData(item.fields.brandLogo)
      }
    }
  },
  pickupPoint: {
    indexName: `TOP_PICKUP_POINTS`,
    contentMap: (item: PickupPointsDataAlgolia): PickupPointsDataAlgolia => ({
      ...item
    })
  }
}

// Define the function to get the Algolia config for a given content type
export const getAlgoliaConfig = (contentType: string) => {
  // Look up the content type in the content types map, and return the corresponding value or false
  return contentTypesMap[contentType] || false
}
