import { merge } from "lodash"
import { PickupPointsData } from "~/layers/checkout/components/Step/Shipping/PickupPoints/Overview.props"

export type PickupPointStateProps = {
  isActive?: boolean
  data?: PickupPointsData["data"][0]
  searchInput?: string
  pickup?: {
    notSelectedOnCta?: number // Created to be a "Math.random()" number, so it gets re-executed everytime we click on the CTA
  }
  invoice?: {
    name?: string
    lastName?: string
    address?: string
    email?: string
    city?: string
    province?: string
    zipCode?: string
    inputConfirmed?: boolean
  }
  retire?: {
    fullName?: string
    phone?: string
    inputConfirmed?: boolean
  }
}

export type PickupPointMetadata = {
  pudo_id: string
  pudo_type: string
  pudo_name?: string
  pudo_address?: string
  pudo_city?: string
  pudo_state?: string
  pudo_country?: string
  pudo_postal_code?: string
  pudo_partner_id?: string
}

export const getPickupPointStateData = () => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  return pickupPointState?.value || {}
}

export const setPickupPointStateData = (data: PickupPointStateProps) => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  const mergedState = merge(getPickupPointStateData(), data)
  pickupPointState.value = mergedState
  return getPickupPointStateData()
}

export const resetPickupPointStateData = () => {
  const pickupPointState = useState<PickupPointStateProps>("pickupPointData")
  pickupPointState.value = {
    data: undefined,
    isActive: false,
    pickup: undefined,
    retire: undefined,
    invoice: undefined,
    searchInput: ""
  }
}

export const pickupPointOrderMetadata = (data: PickupPointsData["data"][0]) => {
  if (data) {
    const metadata: PickupPointMetadata = {
      pudo_id: data.id,
      pudo_type: data.type,
      pudo_name: data.name,
      pudo_address: data.address,
      pudo_city: data.city,
      pudo_state: data.province,
      pudo_country: data.countryCode,
      pudo_postal_code: data.zipCode,
      pudo_partner_id: data.partnerId
    }
    return metadata
  } else return {}
}

export const getPickupPointRadius = () => {
  const defaultRadius = 10000
  const envRadius = useRuntimeConfig()?.public?.PICKUP_POINTS_RADIUS_METERS
  const radius = +(envRadius || defaultRadius)
  return radius // In meters
}
