import { getImageData } from "./wrapperImage"

export const UPPERCASE_ALPHABET = Array.from({ length: 26 }, (_, i) =>
  String.fromCharCode(65 + i)
)

export const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre"
]

export const weekDays = ["lun", "mar", "mer", "gio", "ven", "sab", "dom"]

export const PROFILE_SLUGS = {
  ROOT: "area-personale",
  INFO: "dati",
  SAVED_FOR_LATER: "preferiti",
  ORDERS: "acquisti-resi",
  ORDER: "ordine",
  COUPONS: "buoni",
  ADDRESS: "indirizzi",
  NEW_ADDRESS: "nuovo",
  AVAILABLE_PRODUCTS: "prodotti-disponibili"
}

export const TOP_MAGAZINE_SLUGS = { allPosts: "tutti-i-post" }

export const customPaths = {
  homepage: "/",
  logInSignIn: "/accedi-registrati",
  faqs: "/domande-frequenti",
  checkout: "/checkout",
  deletedProfile: "/profilo-cancellato",
  wikiRoot: "/enciclopedia",
  whoWeAre: "/chi-siamo",
  search: "/ricerca",
  cart: "/carrello",
  termsAndConditions: "/termini-condizioni",
  requestNewProduct: "/richiedi-prodotto",
  magazineRoot: "/top-magazine",
  magazineAllPosts: "/top-magazine/" + TOP_MAGAZINE_SLUGS.allPosts,
  brands: "/brands",
  promotions: "/promozioni",
  promotionsBrand: "/promozioni/brand",
  promotionsInternal: "/promozioni/interne",
  promotionsSpecial: "/promozioni/special",
  cookies: "/cookies",
  findOrder: "/ordine/trova",
  trackOrder: "/ordine/traccia",
  profile: {
    root: `/${PROFILE_SLUGS.ROOT}`,
    orders: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.ORDERS}`,
    order: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.ORDERS}/${PROFILE_SLUGS.ORDER}`,
    address: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.ADDRESS}`,
    newAddress: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.ADDRESS}/${PROFILE_SLUGS.NEW_ADDRESS}`,
    infos: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.INFO}`,
    coupons: `/${PROFILE_SLUGS.ROOT}/${PROFILE_SLUGS.COUPONS}`
  },
  // there are some links left in the translation file of the below paths
  discounts: "/sconti",
  contactUs: "/contatti",
  mostSold: "/promozioni/special/piu-acquistati",
  privacy: "/privacy",
  sitemap: "/sitemap"
}

export const fallbackImage = getImageData({
  altText: "Fallback Image",
  url: "/i5oh5hzamkwo/5k6jyVWV4xYTmuq5MehTIg/8e5a3ae1ad65be21dd5f960ec0e09757/DefaultProductImage.svg",
  width: 200,
  height: 200
})

export const addToCartPosition = {
  listing: "listing",
  pdp: "pdp",
  saveForLater: "save_for_later",
  orderAgain: "order_again",
  bundle: "bundle",
  carousel: "carousel",
  miniCart: "mini_cart",
  accountArea: "account_area"
}

export const addToCartMethod = {
  standard: "standard",
  search: "search",
  cart: "carrello",
  miniCart: "mini_cart",
  checkout: "checkout",
  relatedProducts: "related_products"
}

export const addToCartMethodMapping = {
  [addToCartPosition.listing]: addToCartMethod.search,
  [addToCartPosition.pdp]: addToCartMethod.standard,
  [addToCartPosition.saveForLater]: addToCartMethod.cart,
  [addToCartPosition.orderAgain]: addToCartMethod.cart,
  [addToCartPosition.bundle]: addToCartMethod.standard,
  [addToCartPosition.carousel]: addToCartMethod.relatedProducts,
  [addToCartPosition.miniCart]: addToCartMethod.miniCart,
  [addToCartPosition.accountArea]: addToCartMethod.standard
}
